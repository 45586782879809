@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root, .App {
  height: 100%;
  width: 100%;
  background-color: #e5e7eb;
}


.table-cell-trucate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dark-table {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Set the border color to red (you can change this to any color you want) */
  border: 1px solid red !important;
  /* Other styles such as text color */
}


.app-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

}

.main-content {
  flex-grow: 1;
  height: calc(100vh - 64px); /* Subtract the height of the navbar */
}

#override { 
  z-index: 10 !important;
}

/* trying to move the filter group */
.legend{
  /* transform: translate(600, 250px) !important; */
}
  /* prevevnt label from stopping click */
.MuiFormLabel-root { 
  /* z-index: -10 !important; */
  background-color: transparent !important;
}
[class*="fPSBzf"][class*="kSmiQp"][class*="dKLBtz"][class*="iLbQFp"][class*="dMMuNs"][class*="Axmvo"] {
  display: none !important;
}
.mui-shadow-replica {
  box-shadow: 
  0px 5px 5px -3px rgba(0, 0, 0, 0.2),
  0px 8px 10px 1px rgba(0, 0, 0, 0.14),
  0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  /* Set the border color to red (you can change this to any color you want) */
  /* Other styles such as text color */
}
.toolbar-buttons {
  display: flex;
  gap: 4px; /* Adjust spacing as needed */
}

.case-review--positive {
  background-color: rgb(132,200,135) !important;
}

.case-review--positive:hover {
  background-color: rgb(132,200,135, 0.6) !important;
}

.case-review--negative {
  background-color: rgba(248,161,154) !important;
}

.case-review--negative:hover {
  background-color: rgba(244, 67, 54, 0.3) !important;
}

.data-grid-transition .MuiDataGrid-main {
  transition: all 2s ease-in; /* Apply the transition effect */
}

.selected-comp {
  background-color: #93c5fd !important;
  z-index: 1000 !important;
  /* position: fixed!important; */
}
.selected-comp:hover {
  background-color: rgba(171,207,248) !important;
  /* transition: background-color 0s ease; */
}

.MuiDataGrid-scrollbarFiller{
  border-top: 0px !important;
}

.css-1klb8j8-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.modebar-group {
  display: flex !important;
  padding: 0px !important;
}

.modebar-btn .plotlyjsicon .modebar-btn--logo{
  display: hidden !important;
  padding-top: 20px !important;
}

.plot-container {
  position: relative; /* Ensure it's positioned relative for children */
}
/* .modebar-btn--logo{ */
  /* display: hidden !important; */
/* } */

#demo-simple-select-label{
  background-color: #FFF;
}

.customCheckboxHeader .MuiDataGrid-iconButtonContainer {
  display: none; /* Hide the arrow and gray background */
}
#modebar-8058af{
  visibility: visible !important;
}

.css-lueskc-Input{
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
/* If there's no WF / WV then don't do this? */
.custom-select__control{
  min-height: auto !important;
}

.css-ziuoo8-Input{
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.modebar-btn{
  visibility: visible !important;
}
.modebar-group{
  visibility: visible !important;
}

.MuiDataGrid-editInputCell .MuiInputBase-input {
  padding: 0; /* Removes padding */
  text-align: center;
}

.leaflet-container{
  height: 25vh;
  /* width: 66vw; */
}
/* Custom CSS */
label {
  background-color: #EFF2F7;
  padding: 0 5px;
  padding-top: 0px;
  z-index: 1000;
}
.sticky-content {
  position: sticky;
  top: 0;
  display: block;
  border: 2px solid #000; /* Adjust color as needed */
}

.sticky-border2 {
  box-shadow: 0 1px 0 #64748B, 0 1px 0 #64748B;
  border: 1px solid #64748B; /* Adjust color as needed */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; /* Ensures it spans the full width */
  z-index: 1000; /* Ensure the element stays above other content */
}

.notch-active .tw-input:focus {
  border-top: 1px solid transparent;
}

.table-container {
  height: 52vh; /* Set the height based on your design */
  border: 1px solid #64748B;
}
.table-container table {
  width: 100%;
}


.sticky-border{
  box-shadow: inset 0 1px 0 #64748B, inset 0 -1px 0 #64748B;
}
.dark_border{

}
.custom-marker-number {
  position: absolute;
  top: 40%;
  left: 35%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: 800;
  z-index: 100;
  color: white;
}

.dark {
  color-scheme: dark;
}

.custom-popup-size {
  max-width: 90vw; /* Adjust as needed */
  max-height: 90vh; /* Adjust as needed */
}
@font-face {
  font-family: 'UniversBold';
  src: local('UniversBold'),
  url(./fonts/UniversLTStd-Bold.otf) format('otf');
}

.leaflet-pane { z-index: 0 !important; }

@font-face {
  font-family: 'joanna';
  src: local('joanna'),
  url(./fonts/joanna-sans-nova-cufonfonts/JoannaSansNovaBold.TTF) format('truetype');
}


@font-face {
  font-family: 'UniversLight';
  src: local('UniversLight'),
  url(./fonts/Univers-light-normal.ttf) format('truetype');
}
@font-face {
  font-family: 'UniversBlack';
  src: local('UniversBlack'),
  url(./fonts/UniversBlack.ttf) format('truetype');
}
@font-face {
  font-family: 'Univers';
  src: local('Univers'),
  url(./fonts/UniversLTStd.otf) format('otf');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
