@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');


.joanna{
  font-family: joanna;
}

.MuiFormControlLabel-root {
  background-color: transparent !important; /* Make background transparent */
}

.univers{
  font-family: "UniversBold";
}

.focus-hidden {
  outline: none; /* Remove the browser's default outline */
  border-radius: inherit; /* Ensures smooth corners */
}

.univers-light{
  font-family: "UniversLight";
}
.leaflet-container {z-index:0}
.univers-reg{
  font-family: "Univers";
}
.univers-black{
  font-family: "UniversBlack";
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.custom-max-width {
  max-width: calc(100vw - 160px);
}

.secondDrag{
  top: var(--Tmargin) !important;
  left: var(--Lmargin) !important;
}
.draggingDisplay {
left: var(--Lmargin) !important;
width: 120px !important;
}
.slider-2 {
    accent-color: red;
}

.slider-0 {
  accent-color: red;
}
button[disabled] {
  background-color: gray !important;
  color: white !important;
  cursor: not-allowed !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.App-link {
  color: #61dafb;
}

.active {
  color: rgb(30 64 175);
  transition: 0.3s ease background-color;
}


#coinH {
  animation: App-logo-spin 3s linear infinite;
}



/* The mobile menu */
@media (max-width: 576px) {
  .content {
      padding-top: 51px;
  }
}

@media (min-width: 577px) {
.pt-scroll {
  padding-top: 51px;
}

.draggable{
  /* set this value baseed on your mousedown. */
}

.testing{
 text-color: white!important;
}
.nav-sticky {
  position: fixed!important;
  min-width: 100%;
  top: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
  transition: all .25s ease-in;
  z-index: 1;
}
}

/* HAMBURGER MENU */

.hamburger {
cursor: pointer;
width: 48px;
height: 48px;
transition: all 0.25s;
}

.hamburger__top-bun,
.hamburger__bottom-bun {
content: '';
position: absolute;
width: 24px;
height: 2px;
background: #000;
transform: rotate(0);
transition: all 0.5s;
}

.hamburger:hover [class*="-bun"] {
background: #333;
}

.hamburger__top-bun {
transform: translateY(-5px);
}

.hamburger__bottom-bun {
transform: translateY(3px);
}

.open {
transform: rotate(90deg);
transform: translateY(-1px);
}

.open .hamburger__top-bun {
transform:
  rotate(45deg)
  translateY(0px);
}

.open .hamburger__bottom-bun {
transform:
  rotate(-45deg)
  translateY(0px);
}